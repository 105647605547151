class TokenService {
    getLocalRefreshToken() {
      const user = JSON.parse(localStorage.getItem("user"));
      return user?.refreshToken;
    }
  
    getLocalAccessToken() {
      const user = JSON.parse(localStorage.getItem("user"));
      return user?.token;
    }
  
    updateLocalAccessToken(token) {
      let user = JSON.parse(localStorage.getItem("user"));
      user.token = token;
      localStorage.setItem("user", JSON.stringify(user));
    }
  
    getUser() {
      return JSON.parse(localStorage.getItem("user"));
    }
  
    setUser(user) {
      console.log(JSON.stringify(user));
      localStorage.setItem("user", JSON.stringify(user));
    }
  
    removeUser() {
      localStorage.removeItem("user");
    }

    getUserName() {
        const user = JSON.parse(localStorage.getItem("user"));
        return user?.username;
    }

    getPersonGuid() {
      const user = JSON.parse(localStorage.getItem("personInfo"));
      return user?.PersonsGuid;
    }

    getExpTime() {
        const user = JSON.parse(localStorage.getItem("user"));
        return user?.idTokenClaims?.exp;
    }
    getExpiryTimeForToken() {
      const user = JSON.parse(localStorage.getItem("user"));
      return user?.idTokenClaims;
    }
  }
  
  export default new TokenService();