import { configureStore } from "@reduxjs/toolkit";
import offCanvesReducer from "../features/offCanvesSlice";
import loaderReducer from "../features/loaderSlice";
import showContentReducer from "../features/showContentSlice";
import showAccountFormReducer from "../features/showAccountFormSlice";
import reloadDataTableReducer from "../features/reloadDataTableSlice";
import operatingHoursReducer from "../features/operatingHoursSlice";
import userReducer from "../features/userSlice";
import profileReducer from "../features/profileSlice";
import bayReducer from '../features/baysSlice';

export const store = configureStore({
  reducer: {
    offcanves: offCanvesReducer,
    loader: loaderReducer,
    accountForm: showAccountFormReducer,
    datatableReload: reloadDataTableReducer,
    showContent: showContentReducer,
    operatingHours: operatingHoursReducer,
    userValues: userReducer,
    profile: profileReducer,
    bays: bayReducer,
  },
});
