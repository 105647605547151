import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BAY_DIRECTION, COUNT_DOWN_TIMER, VENUE_GUID, bayStates } from '../constants';
import { filterObject, filterObjectByDisable, convertArrayToObject, getCountByBayStates, sortBays, sortFloorsDesc, filterObjectByDisableMulti, sortByKey, filterIntakeObjectByDisable, filterObjectWithMultiParam, orderBy } from '../commonFunctions';
import dataService from '../apiServices/data.service';
import { toast } from 'react-toastify';

const initialState = {
  bays: [],
  allBays: [],
  bay_states: bayStates,
  floors: [],
  all_state: 0,
  loading: 'idle',
  filterApplied: false,
  error: null,
  checked_intake_bays: [],
  checked_server: null,
};

export const getBays = createAsyncThunk('bays/getBays', async () => {
  
  return dataService.getBays(/* {
    "params": { "keys": "bay_guid, bay_number, Floor, is_deleted" },
    "filter": { "venue_guid": VENUE_GUID }
  } */ { "VenueGuid": VENUE_GUID }).then((response) => {
    if (response.data.status == 'success') {
      return response.data;
    }
  }).catch((err) => {
    toast.error(err.message)
  })
})



export const baySlice = createSlice({
  name: 'bays',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    checkedServer: (state, action) => {
      if (action.payload !== undefined) {
        state.checked_server = action.payload;
      }
    },
    checkedBay: (state, action) => {
      if (action.payload !== undefined) {
       if(action.payload.checked)
       {
        state.checked_intake_bays = [
          ...state.checked_intake_bays,
          action.payload.id
        ];
       }else{
        state.checked_intake_bays = state.checked_intake_bays.filter(
          function (item) {
            return item != action.payload.id;
          }
        );
       }


        //state.allBays[action.payload.id].is_checked = (action.payload.checked) ? 1 : 0;
         
       /*  state.allBays = convertArrayToObject( Object.values(state.allBays).map((checkbox) =>
        checkbox.BayGuid === action.payload ? { ...checkbox, is_checked: !checkbox.is_checked } : { ...checkbox, is_checked: false }
      ), 'BayGuid') */

      }
    },

    resetCheckedIntakeBays: (state, action) => {
      state.checked_intake_bays = []
      state.checked_server = null
    },

    // Use the PayloadAction type to declare the contents of `action.payload`
  },

  extraReducers: (builder) => {
    builder.addCase(getBays.pending, (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
      }
    })
    builder.addCase(getBays.fulfilled, (state, action) => {
      if (state.loading === 'pending') {
        if (action.payload.status == 'success') {
          state.bays = (convertArrayToObject(action.payload.response.bays, 'BayGuid'))
          state.floors = sortFloorsDesc(action.payload.response.floors)

          if (!state.filterApplied) {

            state.bays = Object.values(state.bays).map((item) => ({
              ...item,
              is_deleted: 0,
              is_checked: 0
            }))


            state.allBays = convertArrayToObject(orderBy(state.bays, 'BayNumber', (BAY_DIRECTION == 'rtl') ? 'desc' : 'asc'), 'BayGuid')
            //state.allBays = state.bays
          }


        
        }

        state.loading = 'idle'
      }
    })
    builder.addCase(getBays.rejected, (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        state.error = 'Error occured'
      }
    })
  },
});

export const { checkedServer, checkedBay, resetCheckedIntakeBays} = baySlice.actions;

export default baySlice.reducer

