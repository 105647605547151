import axios from "axios";
import TokenService from "./token.service";
import authService from "./auth.service";
import {
  API_URL,
  APP_GUID,
  APP_NAME,
  APP_VERSION,
  LOGOUT_VARNAME,
  VENUE_GUID,
} from "../constants";
import { msalInstance } from "..";

const instance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    VenueGUID: VENUE_GUID,
    AppName: APP_NAME,
    AppVersion: APP_VERSION,
    AppGUID: APP_GUID,
  },
});

instance.interceptors.request.use(
  (config) => {
    const username = TokenService.getUserName();
    const token = TokenService.getLocalAccessToken();

    if (token) {
      config.headers["Authorization"] = "Bearer " + token; // for Spring Boot back-end
      //config.headers["x-access-token"] = token; // for Node.js Express back-end
    }
    if (username) {
      config.headers["userEmail"] = username;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig) {
      if (originalConfig.url.split("/").pop() !== "login" && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          msalInstance.logoutRedirect({
            postLogoutRedirectUri: "/login",
            account: msalInstance.getActiveAccount(),
            onRedirectNavigate: () => {
              localStorage.setItem(LOGOUT_VARNAME, Date.now().toString());
              authService.logout();
              window.location.replace("/login");
              return false;
            },
          });
        }
      }
    }

    return Promise.reject(err);
  }
);

export default instance;
