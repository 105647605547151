import {
  API_URL,
  API_URL_UPDATED,
  APP_GUID,
  APP_NAME,
  APP_VERSION,
  FLITE_API_AZURE,
  VENUE_GUID,
  appInfo,
} from "../constants";
import api from "./api";

class DataService {
  app_info = appInfo;

  /* Employee */

  async getGeneric(payload = {}) {
    return api.post(API_URL + "generic-module/get-generic", {
      request: payload,
      app_info: this.app_info,
    });
  }

  async getEmployee(payload = {}) {
    return api.post(
      API_URL_UPDATED + "employees-module/get-employees",
      payload
    );
  }

  async getFloorPlans(payload = {}) {
    return api.post(
      API_URL_UPDATED + "floormanagement-module/get-floor-plans",
      payload
    );
  }

  async addFloorPlan(payload = {}, uuid = null) {
    return api.post(
      API_URL_UPDATED + "floormanagement-module/insert-floor-plans",
      payload, { headers: {      "TransactionGUID": uuid } }
    );
  }

  async addFloorShift(payload = {}, uuid = null) {
    return api.post(
      API_URL_UPDATED + "floormanagement-module/insert-shifts",
      payload, { headers: {      "TransactionGUID": uuid } }
    );
  }

  async getFloorShifts(payload = {}) {
    return api.post(
      API_URL_UPDATED + "floormanagement-module/get-shifts",
      payload
    );
  }

  async getBays(payload = {}) {
    return api.post(API_URL_UPDATED + "bays-module/get-bays", payload);
  }

  async getServersForShift(payload = {}) {
    return api.post(
      API_URL_UPDATED + "floormanagement-module/get-servers-for-shift",
      payload
    );
  }

  async deleteFloorPlan(payload = {}) {
    return api.post(
      API_URL_UPDATED + "floormanagement-module/delete-floor-plans",
      payload
    );
  }

  async getServers(payload = {}) {
    return api.post(
      API_URL_UPDATED + "floormanagement-module/get-servers",
      payload
    );
  }

  async saveServersForShift(payload = {}, uuid = null) {
    return api.post(
      API_URL_UPDATED + "floormanagement-module/insert-or-update-servers-for-shift",
      payload, { headers: {      "TransactionGUID": uuid } }
    );
  }

  async removeServerFromShift(payload = {}) {
    return api.post(
      API_URL_UPDATED + "floormanagement-module/remove-server",
      payload
    );
  }


  async deleteShift(payload = {}) {
    return api.post(
      API_URL_UPDATED + "floormanagement-module/delete-shifts",
      payload
    );
  }

  async updateBayServerAssignments(payload = {}) {
    return api.post(
      API_URL_UPDATED + "floormanagement-module/update-bay-server-assignments",
      payload
    );
  }
  
  async updateEmployee(payload = {}) {
    return api.post(API_URL_UPDATED + "employees-module/update-employee-avatar", payload);
  }

  async getAvatar(payload = {}) {
    return api.post(API_URL_UPDATED + "employees-module/get-avatars", payload);
  }

  async getFloorPlanForShift(payload = {}) {
    return api.post(
      API_URL_UPDATED + "floormanagement-module/get-floor-plan-for-shift",
      payload
    );
  }
  async getEmployeeByRowGuid(payload = {}) {
    return api.post(
        API_URL_UPDATED + "employees-module/get-employee-details",
        payload
    );
  }

  async getAppAccessSingle(payload = {}) {
    return api.post(API_URL_UPDATED + "appsecurity-module/get-app-access", payload);
  }

  async getAppAccess(payload = {}) {
    return api.post(
      API_URL_UPDATED + "appsecurity-module/get-app-access",
      payload
    );
  }

  async getAppAccessAwait(payload = {}) {
    const res = await api.post(
      API_URL_UPDATED + "appsecurity-module/get-app-access",
      payload
    );
    return res;
  }

  async findByEmail(payload = {}) {
    return api.post(
      API_URL_UPDATED + "/employees-module/find-by-email",
      payload
    );
  }

}

export default new DataService();
